.capture-button {
  width: 70px;
  /* adjust as needed */
  height: 70px;
  /* adjust as needed */
}

body {
  overflow: hidden;
}

.circuler-uploading {
  position: absolute;
}

.capture-video {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.c-camera-feed__viewer{
  width: 100%;
  height: 100%;
}