.simple-keyboard {
  pointer-events: none;
  height: 100%;
}

.react-simple-keyboard {
  background-color: #f2f2f2;
  color: #333;
  font-size: 30px;
  cursor: none;
  padding: 0px !important;
}


.hg-button {
  height: 100% !important;
  margin: 0px !important;
}

.react-simple-keyboard .hg-button {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  /* Adjust this to change the border color */
  padding: 10px;
  margin: 0;
  /* No margin to remove space between buttons */
  box-sizing: border-box;
  pointer-events: all;

  /* Enable pointer events for the buttons */
}

.react-simple-keyboard .hg-button span {
  font-size: 40px;
}

.react-simple-keyboard .hg-functionBtn {
  background-color: #f2f2f2;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0;
  width: 20px;
  /* No margin to remove space between buttons */
}

.hg-rows {
  height: 100%;
  pointer-events: none;
}

.hg-row {
  height: 25%;
  margin: 0px !important;
}

.keyboard-wrapper {
  z-index: 1000;
  height: 35%;
}

.keyboard-wrapper {
  width: 100vw;
  bottom: 0px;
  background: black;
  position: absolute;
  pointer-events: all;
}
